import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const EntriesRight = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="553"
        height="560"
        viewBox="0 0 553 560"
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
      >
        <path
          d="M305.65 315.296L305.65 478.323C305.65 484.758 300.47 489.993 294.035 490.061C248.916 490.539 212.084 454.094 212.084 408.972V328.797C212.084 315.644 222.821 305.02 235.973 305.16L296.251 305.797C301.458 305.852 305.65 310.089 305.65 315.296Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <rect
          x="242.568"
          y="475.43"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-180 242.568 475.43)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M269.814 213.435H196.032C190.785 213.435 186.532 209.182 186.532 203.935V131.906C186.532 125.258 191.921 119.869 198.569 119.869H199.917C245.056 119.869 281.648 156.462 281.648 201.601C281.648 208.137 276.35 213.435 269.814 213.435Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M272.306 343.658H229.043C180.735 343.658 141.574 304.497 141.574 256.189C141.574 252.822 144.304 250.092 147.672 250.092H272.148C277.395 250.092 281.648 254.345 281.648 259.592V334.316C281.648 339.475 277.466 343.658 272.306 343.658Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M336.658 179.491V129.826C336.658 81.5183 297.497 42.3573 249.19 42.3573C245.822 42.3573 243.092 45.0873 243.092 48.455V172.931C243.092 178.178 247.345 182.431 252.592 182.431H333.717C335.342 182.431 336.658 181.115 336.658 179.491Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M9.84238 118.32L53.1055 118.32C101.413 118.32 140.574 157.481 140.574 205.789C140.574 209.156 137.844 211.886 134.476 211.886L9.99999 211.886C4.75329 211.886 0.5 207.633 0.5 202.386L0.5 127.662C0.5 122.503 4.68272 118.32 9.84238 118.32Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M54.125 439.121V437.679C54.125 434.252 56.9034 431.473 60.3308 431.473H138.191C143.438 431.473 147.691 435.726 147.691 440.973V520.492C147.691 523.86 144.961 526.59 141.593 526.59C93.286 526.59 54.125 487.429 54.125 439.121Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M336.658 498.031V496.589C336.658 493.162 333.88 490.383 330.452 490.383H252.592C247.345 490.383 243.092 494.637 243.092 499.883V579.402C243.092 582.77 245.822 585.5 249.19 585.5C297.497 585.5 336.658 546.339 336.658 498.031Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M557.748 339.904V338.462C557.748 335.035 554.97 332.256 551.542 332.256H473.682C468.435 332.256 464.182 336.51 464.182 341.756V421.275C464.182 424.643 466.912 427.373 470.28 427.373C518.587 427.373 557.748 388.212 557.748 339.904Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <rect
          x="47.7598"
          y="345.416"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 47.7598 345.416)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M274.098 296.953V222.387C274.098 217.14 278.351 212.887 283.598 212.887H358.164C363.41 212.887 367.664 217.14 367.664 222.387V296.953C367.664 302.2 363.41 306.453 358.164 306.453H283.598C278.351 306.453 274.098 302.2 274.098 296.953Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M308.965 396.801H352.229C400.536 396.801 439.697 435.962 439.697 484.269C439.697 487.637 436.967 490.367 433.599 490.367H309.123C303.876 490.367 299.623 486.114 299.623 480.867V406.143C299.623 400.983 303.806 396.801 308.965 396.801Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <path
          d="M270.598 94.0662L62.8559 94.0662C58.3841 94.0662 54.759 90.441 54.759 85.9692C54.759 38.7659 93.0249 0.500031 140.228 0.500031L270.598 0.500031C275.844 0.500031 280.098 4.75333 280.098 10V84.5662C280.098 89.8129 275.844 94.0662 270.598 94.0662Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

EntriesRight.defaultProps = {};

export default EntriesRight;
