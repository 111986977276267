import React from "react";
import { Text, Container, Button } from "@atoms";
import { Card } from "@molecules";
import EntriesPatternLeft from "@svg/EntriesPatternLeft";
import EntriesPatternRight from "@svg/EntriesPatternRight";
import clsx from "clsx";

const patternColor = {
  yellowWhite: "text-white",
  purpleTeal: "text-teal",
  slate: "text-transparent",
};

const bgColor = {
  yellowWhite: "bg-yellow",
  purpleTeal: "bg-purple",
  slate: "bg-slate",
};

const ColoredBackdrop = ({ colorScheme }) => {
  return (
    <div
      className={clsx(
        "absolute h-full w-full rounded-3xl rounded-br-5xl md:rounded-br-8xl",
        bgColor[colorScheme]
      )}
    >
      <EntriesPatternLeft
        className={clsx(
          "absolute -bottom-4 left-0 z-0 w-[27.5rem]",
          patternColor[colorScheme]
        )}
      />
      <EntriesPatternRight
        className={clsx(
          "absolute -right-24 -top-32 z-0 w-[27.5rem]",
          patternColor[colorScheme]
        )}
      />
    </div>
  );
};

const CardGrid = ({
  heading,
  descriptor,
  button,
  colorScheme = "yellowWhite",
  cards,
}) => {
  return (
    <section className="my-10 sm:my-20">
      <Container smallerPadding>
        <div
          className={clsx("relative flex flex-col overflow-hidden md:flex-row")}
        >
          <ColoredBackdrop colorScheme={colorScheme || "yellowWhite"} />

          <Container
            variant="sm"
            className="relative z-10 py-10 text-center sm:pt-16"
          >
            <Text
              variant="h2"
              className={clsx({
                "text-purple": colorScheme === "yellowWhite" || !colorScheme,
                "text-white":
                  colorScheme === "purpleTeal" || colorScheme === "slate",
              })}
            >
              {heading || "Explore Other Resources"}
            </Text>

            {descriptor && (
              <Text
                variant="body"
                className={clsx("mx-auto mb-10 mt-6 max-w-2xl", {
                  "text-slate": colorScheme === "yellowWhite" || !colorScheme,
                  "text-white":
                    colorScheme === "purpleTeal" || colorScheme === "slate",
                })}
              >
                {descriptor}
              </Text>
            )}

            {!!cards?.length && (
              <div className="relative z-10 my-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                {cards?.map(card => {
                  return <Card key={card.uid} {...card} className="h-full" />;
                })}
              </div>
            )}

            {/* expliciptly set button */}
            {button?.url && (
              <Button
                to={button?.url}
                color={colorScheme === "yellowWhite" ? "purple" : "white"}
                size="md"
              >
                <Text>{button?.text}</Text>
              </Button>
            )}
          </Container>
        </div>
      </Container>
    </section>
  );
};

export default CardGrid;
