import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const EntriesLeft = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="638"
        height="383"
        viewBox="0 0 638 383.5"
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M274.098 313.028H89.0674C89.3366 261.307 131.348 219.462 183.132 219.462H274.098V313.028Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="104.568"
          y="242.944"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 104.568 242.944)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M346.564 270.189V196.407C346.564 191.16 350.818 186.907 356.064 186.907H428.094C434.741 186.907 440.131 192.296 440.131 198.944V200.292C440.131 245.431 403.538 282.023 358.399 282.023C351.863 282.023 346.564 276.725 346.564 270.189Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M229.342 272.681V229.418C229.342 181.11 268.503 141.949 316.81 141.949C320.178 141.949 322.908 144.679 322.908 148.047V272.523C322.908 277.77 318.655 282.023 313.408 282.023H238.684C233.525 282.023 229.342 277.841 229.342 272.681Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M380.509 344.034H430.174C478.481 344.034 517.642 304.873 517.642 256.566C517.642 253.198 514.912 250.468 511.545 250.468H387.068C381.822 250.468 377.568 254.721 377.568 259.968V341.093C377.568 342.718 378.885 344.034 380.509 344.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M441.68 3.81546V53.4802C441.68 101.788 402.519 140.949 354.211 140.949C350.844 140.949 348.114 138.219 348.114 134.851V10.3746C348.114 5.12792 352.367 0.874634 357.614 0.874634L438.739 0.874634C440.363 0.874634 441.68 2.19129 441.68 3.81546Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M140.879 54.5H142.321C145.748 54.5 148.527 57.2784 148.527 60.7058V138.566C148.527 143.813 144.273 148.066 139.027 148.066H59.5078C56.1402 148.066 53.4102 145.336 53.4102 141.968C53.4102 93.661 92.5711 54.5 140.879 54.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M87.9685 344.034H89.4106C92.838 344.034 95.6164 341.256 95.6164 337.828V259.968C95.6164 254.721 91.3631 250.468 86.1164 250.468H6.59764C3.23 250.468 0.5 253.198 0.5 256.566C0.5 304.873 39.661 344.034 87.9685 344.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M240.743 484.557H145.628C145.897 536.278 187.909 578.123 239.693 578.123H240.743V484.557Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="227.584"
          y="48.1338"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M269.047 281.472H343.613C348.86 281.472 353.113 285.725 353.113 290.972V365.538C353.113 370.785 348.86 375.038 343.613 375.038H269.047C263.8 375.038 259.547 370.785 259.547 365.538V290.972C259.547 285.725 263.8 281.472 269.047 281.472Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M89.0667 483.556L89.0667 343.483H182.633V389.491C182.633 441.275 140.788 483.287 89.0667 483.556Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M458.934 270.972V63.2305C458.934 58.7587 462.559 55.1335 467.031 55.1335C514.234 55.1335 552.5 93.3994 552.5 140.603V270.972C552.5 276.219 548.246 280.472 543 280.472H468.434C463.187 280.472 458.934 276.219 458.934 270.972Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

EntriesLeft.defaultProps = {};

export default EntriesLeft;
